import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from 'react-paginate';
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import { FiDownload } from "react-icons/fi"
import { Button, FormGroup, Input } from 'reactstrap';
import { userList, updateUserStatus } from "../../../redux/action/userAction";
import { TbRefresh } from "react-icons/tb"
import { CSVLink } from "react-csv";
import { IoPencil } from "react-icons/io5"
import Switch from '@mui/material/Switch';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const Usersidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [search, setSearch] = useState('')
    const listData = useSelector((state) => state.user);
    const [perPage, SetcurrentPage] = useState(10)
    const { loader, userListData } = listData;
    const filename = 'pgpt.csv';
    const headers = ['bnbValue', 'gspValue', 'status',];
    let data = userListData.data && userListData.data[0].data.length > 0 ? userListData.data && userListData.data[0].data : []
    let countUserData =
        userListData && (userListData.data && userListData.data[0].total.length > 0) ? userListData.data[0].total[0].count / 10 : []
    useEffect(() => {
        let obj = { searchVal: '', pageNumber: 1 }
        dispatch(userList(obj));
    }, [userList]);


    const handlePageChange = (e) => {
        const selectedPage = e.selected + 1
        SetcurrentPage(10 * selectedPage)
        let obj = { searchVal: '', pageNumber: selectedPage }
        dispatch(userList(obj))
    }



    const handleSearch = (e) => {
        const { name, value } = e.target;
        setSearch(value)
        let obj = { searchVal: value, pageNumber: 1 }
        dispatch(userList(obj));
    }

    const refreshPage = () => {
        let obj = { searchVal: '', pageNumber: 1 }
        dispatch(userList(obj));
        setSearch("")
    }

    const handleAuthentication = (userId) => {
        dispatch(updateUserStatus(userId)).then(res => {
            if (res.code == 200) {
                toast.success(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                setTimeout(() => {
                    let obj = { searchVal: '', pageNumber: 1 }
                    dispatch(userList(obj))
                }, 2000)


            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        })

    }

    const editUser = (userid) => {
        localStorage.setItem('userId', userid)
        navigate('/edit-user')

    }

    return (
        <>
            <div className="dashRightside">
                <div className="transBg">
                    <ToastContainer />
                    <div className="transUserOuter">
                        <div className="transHd">Moderators</div>
                        <div className="transFltr">
                            <div className="daterange">

                            </div>
                            <div className="downBtn">
                                <CSVLink data={data} filename={filename}>
                                    <Button><FiDownload /> </Button>
                                </CSVLink>
                            </div>
                            <div className="srchBx">
                                <FormGroup>
                                    <Input type="text" name="search" id="exampleEmail" placeholder="Search..." value={search} onChange={handleSearch} />
                                </FormGroup>
                            </div>
                            <div className="refreshBtn">
                                <Button onClick={refreshPage}><TbRefresh /></Button>
                            </div>
                            <div className="fltrBx"><Link to='/create-user'> <Button className="createBtn">Create Moderator</Button></Link></div>


                        </div>
                    </div>

                    <div className="tableBx">
                        <table cellPadding="0" cellSpacing="0" border="0">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th> User Name</th>
                                    <th>User Email</th>
                                    <th>Team Count</th>
                                    <th>Session Count</th>
                                    <th>Created Date</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {userListData.data && userListData.data[0].data.length > 0 ? (
                                    userListData.data[0].data.map((data, i) => (
                                        <tr key={i}>
                                            <td>{i + 1 + (perPage - 10)}</td>
                                            <td>{data.userName}</td>
                                            <td>{data.email}</td>
                                            <td>101</td>
                                            <td>{data.sessionCount}</td>
                                            <td>{moment(data.createdAt).format('MMMM-DD-YYYY')}</td>
                                            <td> <div className='ms-2 toggleBtn' onClick={() => handleAuthentication(data._id)}> <Switch {...label} defaultChecked={data.status == 'Active' ? true : false} /></div>

                                            </td>
                                            <td className="actionBtns">

                                                <button onClick={() => editUser(data._id)}> <IoPencil /></button></td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr><td>Data not found</td></tr>
                                )
                                }

                            </tbody>
                        </table>
                    </div>
                    <div className="paginationBx">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={Math.ceil(countUserData)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Usersidebar;
