import React, { Component } from 'react';
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import Login from '../components/pages/login/login';
import Forget from '../components/pages/forgetPassword/forget';
import Otp from '../components/pages/verifyotp/otp'
import Rsetpassword from '../components/pages/resetPassword/resetPassword';
import Dashboard from '../components/pages/dashboard/dashboard';
import Setting from '../components/pages/setting/setting';
import Partners from '../components/pages/partners/partners';
import HowitWorks from '../components/pages/how-it-works/how-it-works';
import User from '../components/pages/user/user';
import Edituser from '../components/pages/edit-user/edit-users'
import Createuser from '../components/pages/signup/signup';
import Questions from '../components/pages/questions/questions';
import Editquestion from '../components/pages/edit-questions/edit-questions';
import Createquestion from '../components/pages/create-questionns/create-questions';
import Flaggedreviews from '../components/pages/flaggedReviews/flaggedReviews';
import Editflaggedreview from '../components/pages/edit-flagreview/edit-flagged';
import NftsDetails from '../components/pages/nfts-details/nfts-details';
import Categroys from '../components/pages/categorys/categorys';
import CollectionsDetails from '../components/pages/collections-details/collections-details';
import CreateNfts from '../components/pages/create-nfts/create-nfts';
import CreateCategorys from '../components/pages/create-categorys/create-categorys';
import Editcategory from '../components/pages/edit-categorys/edit-categorys';
import Supercategroys from '../components/pages/supercategorys/superCats';
import Createsupercategory from '../components/pages/create-supercats/create-supercategorys';
import Editsupercategory from '../components/pages/edit-supercategorys/edit-supercategorys';
import InfoFormDetailsRightsidebar from '../components/pages/infoForm/info-form-details';
import ImageDownload from '../components/pages/imageDownload/image-download';
import Units from "../components/pages/units/units"
import CreateUnits from "../components/pages/create-units/create-units"
import EditUnit from "../components/pages/edit-units/edit-units"
import CreateBattalions from "../components/pages/create-battalions/create-battalions"
import EditBattalion from "../components/pages/edit-battalions/edit-battalions"
import Battalions from "../components/pages/battalions/battalions"

let tokens = localStorage.getItem('adminToken');
const ProtectedRoute = () => {
    let auth = tokens
    return auth ? <Outlet /> : <Navigate to="/" />
}

export default class Layout extends Component {


    render() {
        return (
            <Routes>
                {/* public access url */}
                <Route path="/" element={<Login />} />
                <Route path="/forget-password" element={<Forget />} />
                <Route path="/verify-otp" element={<Otp />} />
                <Route path="/rset-password" element={<Rsetpassword />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/moderators" element={<User />} />
                <Route path="/create-user" element={<Createuser />} />
                <Route path="/edit-user" element={<Edituser />} />
                <Route path="/partners" element={<Partners />} />
                <Route path="/how-it-works" element={<HowitWorks />} />
                <Route path="/question" element={<Questions />} />
                <Route path="/edit-question" element={<Editquestion />} />
                <Route path="/create-question" element={<Createquestion />} />
                <Route path="/flagged-review" element={<Flaggedreviews />} />
                <Route path="/edit-flagged-review" element={<Editflaggedreview />} />
                <Route path="/nfts-details" element={<NftsDetails />} />
                <Route path="/category" element={<Categroys />} />
                <Route path="/create-categorys" element={<CreateCategorys />} />
                <Route path="/edit-categorys" element={<Editcategory />} />
                <Route path="/collections-details" element={<CollectionsDetails />} />
                <Route path="/create-nfts" element={<CreateNfts />} />
                <Route path="/setting" element={<Setting />} />
                <Route path="/super-categroy" element={<Supercategroys />} />
                <Route path="/create-super-category" element={<Createsupercategory />} />
                <Route path="/edit-super-category" element={<Editsupercategory />} />
                <Route path="/info-form" element={<InfoFormDetailsRightsidebar />} />
                <Route path="/image-download" element={<ImageDownload/>} />
                <Route path="/unit" element={<Units />} />
                <Route path="/create-units" element={<CreateUnits />} />
                <Route path="/edit-units" element={<EditUnit />} />
                <Route path="/battalion" element={<Battalions />} />
                <Route path="/create-battalions" element={<CreateBattalions />} />
                <Route path="/edit-battalions" element={<EditBattalion />} />

            </Routes>
        )

    }
}









{/* <Route element={<ProtectedRoute />}>
<Route path="/dashboard" element={<Dashboard />} exact />
</Route>
<Route element={<ProtectedRoute />}>
<Route path="/moderators" element={<User />} exact />
</Route>
<Route element={<ProtectedRoute />}>
<Route path="/create-user" element={<Createuser />} exact />
</Route> */}